<script setup lang="ts">
import useWebSocket from '@/composables/useWebSocket';
import { useFavicon } from '@vueuse/core';
// import { useRegisterSW } from 'virtual:pwa-register/vue';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// const BaseSnackbar = defineAsyncComponent(() => import('@/components/Base/BaseSnackbar.vue'));

const store = useStore();
store.commit('GET_HOST_NAME');

const isEcocanHost = computed(() => store.state.is_ecocan_host);
const changeFavicon = () => {
  const icon = isEcocanHost.value ? 'ecocan.ico' : 'favicon.ico';
  useFavicon(icon, { baseUrl: '/' });
};

let websocketUrl = import.meta.env.VITE_APP_WEBSOCKET_URL;

const currentUrl = new URL(window.location.href);
if (currentUrl.host === 'dashboard.staging.connectqr.ai') {
  websocketUrl = 'wss://dashboard.staging.connectqr.ai/wss';
}

useWebSocket(websocketUrl, store);

// const { needRefresh, updateServiceWorker } = useRegisterSW();

onMounted(() => {
  changeFavicon();
});
</script>

<template>
  <div class="application theme--light" id="root">
    <v-app>
      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <component :is="Component" />
        </Transition>
      </router-view>
      <!-- <BaseSnackbar :show="offlineReady" text="App ready to work offline." /> -->
      <!-- <BaseSnackbar
        :show="needRefresh"
        text="A new version of Connect is available."
        content="Update"
        @on-update="updateServiceWorker"
      /> -->
    </v-app>
  </div>
</template>

<style lang="scss">
.v-application a {
  color: rgb(var(--v-theme-primary));
}
</style>
